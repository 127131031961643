import {
  BasicCompanyKycInfo,
  CompanyKycData,
  CompanyUser,
  CompanyUserDetails,
  CompanyUserStatusEnum,
  CompanyUserUpdateData,
  GeneralChatAnnouncementConversationList,
  GeneralChatConversationList,
  GenerateNDAResponse,
  ListResponse,
  Loan,
  LoanScreening,
  NewCompanyKycData,
  NonPropertyCollateral,
  NonPropertyCollateralScreening,
  Orderbook,
  PortfolioAnnouncement,
  PortfolioChatMessage,
  PortfolioSnapshot,
  PropertyCollateral,
  PropertyCollateralScreening,
  UserInviteRequestData,
} from '../types';
import useClient, { CRUDActions } from './useClient';
import { saveAs } from 'file-saver';

export interface CurrencyOption {
  code: string;
  name: string;
  timestamp: string;
  timezone: string;
}

interface CurrencyOptionsResponse {
  displayCurrency: string | null;
  options: CurrencyOption[];
}

export default function useApi() {
  const client = useClient();

  const fetchPortfolioSnapshot = async (id: number | string) =>
    client.get(`eba/portfolio_snapshots/${id}`).then((response) => response.data as PortfolioSnapshot);
  const portfolioCount = async () =>
    client.get('eba/portfolio_snapshots/total_count/').then((response) => response.data as number);
  const acceptNda = async (portfolioId: number, ndaFileId: number) =>
    client
      .post(`eba/portfolio_snapshots/${portfolioId}/accept_nda/`, { nda_file_id: ndaFileId })
      .then((res) => res.data);
  const generateNda = async (portfolioId: number) =>
    client.post(`eba/portfolio_snapshots/${portfolioId}/generate_nda/`).then((res) => res.data as GenerateNDAResponse);
  const portfolioLoans = async (id: number, page = 1, pageSize = 3, filterValues: string[] = []) =>
    client
      .get(`eba/loans/`, { params: { page, portfolio_snapshot: id, page_size: pageSize, search: filterValues } })
      .then((response) => response.data as ListResponse<Loan>);

  const portfolioLoanScreening = async (portfolioId: number) =>
    client.get(`eba/portfolio_snapshots/${portfolioId}/loan_screening/`).then((res) => res.data as LoanScreening);
  const portfolioPropertyCollateralScreening = async (portfolioId: number) =>
    client
      .get(`eba/portfolio_snapshots/${portfolioId}/property_collateral_screening/`)
      .then((res) => res.data as PropertyCollateralScreening);
  const portfolioNonPropertyCollateralScreening = async (portfolioId: number) =>
    client
      .get(`eba/portfolio_snapshots/${portfolioId}/non_property_collateral_screening/`)
      .then((res) => res.data as NonPropertyCollateralScreening);
  const portfolioChatMessages = async (portfolioId: number, page = 1, pageSize = 20) =>
    client
      .get(`eba/portfolio_snapshots/${portfolioId}/chat_messages/`, {
        params: { page, page_size: pageSize },
      })
      .then((response) => response.data as ListResponse<PortfolioChatMessage>);
  const portfolioPostChatMessage = async (portfolioId: number, data: FormData) =>
    client.post(`eba/portfolio_snapshots/${portfolioId}/post_message/`, data).then((res) => res.data as string);
  const portfolioUnreadChatMessageCount = async (portfolioId: number) =>
    client
      .get(`eba/portfolio_snapshots/${portfolioId}/chat_unread_message_count/`)
      .then((res) => res.data as { unreadMessageCount: number });
  const fetchPortfolioAnnouncements = async (snapshotId: number) =>
    client
      .get(`eba/portfolio_snapshots/${snapshotId}/announcements/`)
      .then((response) => response.data as PortfolioAnnouncement[]);
  const downloadPortfolioAnnouncementAttachment =
    (snapshotId: number) => async (attachmentId: number, attachmentName: string) => {
      const response = await client.get(
        `eba/portfolio_snapshots/${snapshotId}/download_announcement_attachment/?attachment_id=${attachmentId}`,
        {
          responseType: 'blob',
        },
      );

      saveAs(response.data, attachmentName);
    };
  const downloadPortfolioChatAttachment =
    (snapshotId: number) => async (attachmentId: number, attachmentName: string) => {
      const response = await client.get(
        `eba/portfolio_snapshots/${snapshotId}/download_attachment/?attachment_id=${attachmentId}`,
        {
          responseType: 'blob',
        },
      );

      saveAs(response.data, attachmentName);
    };
  const dataRoomAccess = async (snapshotId: number) =>
    client.get(`eba/portfolio_snapshots/${snapshotId}/data_room_access/`).then((res) => res.data);

  const loanPropertyCollaterals = async (loanId: number) =>
    client.get(`eba/loans/${loanId}/property_collaterals/`).then((res) => res.data as PropertyCollateral[] | null);
  const loanNonPropertyCollaterals = async (loanId: number) =>
    client
      .get(`eba/loans/${loanId}/non_property_collaterals/`)
      .then((res) => res.data as NonPropertyCollateral[] | null);

  const fetchCurrencies = async () =>
    client.get('eba/currencies/').then((response) => response.data as CurrencyOptionsResponse);
  const setDisplayCurrency = async (currency: string | null) =>
    client.post('eba/currencies/set_currency/', { currency }).then((response) => response.data as string);

  const confirmBid = async (bidId: string, token: string) =>
    client.post(`marketplace/bids/${bidId}/confirm/`, { token }).then((res) => res.data as unknown);

  const makeBid = async (auctionId: number, price: number) =>
    client.post(`marketplace/bids/`, { auction: auctionId, price }).then((res) => res.data as unknown);

  const saveKycData = async (kycData: BasicCompanyKycInfo | NewCompanyKycData) =>
    client.post('company/kyc_data/', kycData).then((response) => response.data);

  const fetchKycData = async () => client.get('company/kyc_data/').then((response) => response.data as CompanyKycData);

  const uploadKycFile = async (form: FormData) =>
    client.post('company/upload_kyc_file/', form).then((response) => response.data);

  const downloadKycFile = async (kyc_data_id: number, field_name: string, file_name: string) => {
    const response = await client.get(
      `company/download_kyc_file/?kyc_data_id=${kyc_data_id}&field_name=${field_name}`,
      {
        responseType: 'blob',
      },
    );

    saveAs(response.data, file_name);
  };

  const submitForKycVerification = async () =>
    client.post('company/submit_kyc_verification/').then((response) => response.data);

  const fetchCompanyUsers = async () => client.get('company/users/').then((response) => response.data as CompanyUser[]);
  const fetchCompanyUserDetails = async (userId: number) =>
    client.get(`company/users/${userId}/`).then((response) => response.data as CompanyUserDetails);
  const updateCompanyUserDetails = async (userId: number, data: CompanyUserUpdateData) =>
    client.put(`company/users/${userId}/`, data).then((response) => response.data as CompanyUserDetails);

  const userStatusChange = async (userId: number, newRole: CompanyUserStatusEnum) =>
    client
      .post(`company/users/${userId}/change_status/`, { id: userId, status: newRole })
      .then((response) => response.data);

  const inviteUser = async (data: UserInviteRequestData) =>
    client.post('company/invite_user/', data).then((response) => response.data);

  const resendInvite = async (data: UserInviteRequestData) =>
    client.post('company/resend_invite/', data).then((response) => response.data);

  const fetchGeneralChatConversationList = async () =>
    client
      .get(`marketplace/message_center/chat_conversation_list/`)
      .then((response) => response.data as GeneralChatConversationList[]);

  const fetchGeneralChatMessages = async (conversationId: number, page = 1, pageSize = 20) =>
    client
      .get(`marketplace/message_center/chat_messages?conversation_id=${conversationId}`, {
        params: { page, page_size: pageSize },
      })
      .then((response) => response.data as ListResponse<PortfolioChatMessage>);

  const postGeneralChatMessage = async (conversationId: number, data: FormData) =>
    client
      .post(`marketplace/message_center/post_message/?conversation_id=${conversationId}`, data)
      .then((res) => res.data as string);

  const downloadGeneralChatAttachment = async (attachmentId: number, attachmentName: string) => {
    const response = await client.get(`marketplace/message_center/download_attachment/?attachment_id=${attachmentId}`, {
      responseType: 'blob',
    });

    saveAs(response.data, attachmentName);
  };

  const downloadGeneralChatAnnouncementAttachment = async (attachmentId: number, attachmentName: string) => {
    const response = await client.get(
      `marketplace/message_center/download_announcement_attachment/?attachment_id=${attachmentId}`,
      {
        responseType: 'blob',
      },
    );

    saveAs(response.data, attachmentName);
  };

  const postGeneralChatConversation = async (data: FormData) =>
    client.post('marketplace/message_center/post_conversation/', data).then((res) => res.data as string);
  const fetchGeneralChatAnnouncementConversationList = async () =>
    client
      .get(`marketplace/message_center/chat_announcement_conversation_list/`)
      .then((response) => response.data as GeneralChatAnnouncementConversationList[]);
  const fetchGeneralChatAnnouncements = async (conversationId: number, page = 1, pageSize = 20) =>
    client
      .get(`marketplace/message_center/chat_announcements?conversation_id=${conversationId}`, {
        params: { page, page_size: pageSize },
      })
      .then((response) => response.data as ListResponse<PortfolioChatMessage>);

  const acceptTos = async () => client.post('accept_tos/').then((res) => res.data as string);

  return {
    client,
    portfolio_snapshot: {
      ...CRUDActions<PortfolioSnapshot>('eba/portfolio_snapshots/'),
      totalCount: portfolioCount,
      fetchPortfolioSnapshot,
      fetchCurrencies,
      setDisplayCurrency,
      acceptNda,
      generateNda,
      loans: portfolioLoans,
      loanScreening: portfolioLoanScreening,
      propertyCollateralScreening: portfolioPropertyCollateralScreening,
      nonPropertyCollateralScreening: portfolioNonPropertyCollateralScreening,
      portfolioChatMessages,
      portfolioPostChatMessage,
      portfolioUnreadChatMessageCount,
      fetchPortfolioAnnouncements,
      dataRoomAccess,
      downloadPortfolioAnnouncementAttachment,
      downloadPortfolioChatAttachment,
    },
    loan: {
      ...CRUDActions<Loan>('eba/loans/'),
      propertyCollaterals: loanPropertyCollaterals,
      nonPropertyCollaterals: loanNonPropertyCollaterals,
    },
    bid: {
      make: makeBid,
      confirm: confirmBid,
    },
    orderbook: CRUDActions<Orderbook>('marketplace/orderbook/'),
    company: {
      saveKycData,
      fetchKycData,
      uploadKycFile,
      downloadKycFile,
      submitForKycVerification,
      fetchCompanyUsers,
      fetchCompanyUserDetails,
      updateCompanyUserDetails,
      userStatusChange,
      inviteUser,
      resendInvite,
    },
    messageCenter: {
      fetchGeneralChatConversationList,
      fetchGeneralChatMessages,
      postGeneralChatMessage,
      postGeneralChatConversation,
      fetchGeneralChatAnnouncementConversationList,
      fetchGeneralChatAnnouncements,
      downloadGeneralChatAttachment,
      downloadGeneralChatAnnouncementAttachment,
    },
    acceptTos,
  };
}
