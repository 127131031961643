import { Field, Form, Formik } from 'formik';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { TextField } from 'formik-mui';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import ArrowRight from '../../shared/components/icons/ArrowRight';
import styles from '../auth.module.scss';
import { useAuth } from '../../shared/hooks/useAuth';
import { AxiosError } from 'axios';
import { useTheme } from '@mui/material/styles';

interface Props {
  setError: (value: boolean) => void;
  setErrorMessage: (value: string | undefined) => void;
}

export default function LoginForm({ setError, setErrorMessage }: Props) {
  const requiredMsg = 'This field cannot be empty.';
  const validationSchema = Yup.object({
    email: Yup.string().email().required(requiredMsg),
    password: Yup.string().required(requiredMsg),
  });

  const { login } = useAuth();

  const theme = useTheme();

  const { search } = useLocation();
  const refCode = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('ref');
  }, [search]);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setError(false);
        setErrorMessage(undefined);
        try {
          await login({ refCode, ...values });
        } catch (e) {
          if ((e as AxiosError).response?.data.nonFieldErrors.length === 1) {
            setErrorMessage((e as AxiosError).response?.data.nonFieldErrors[0]);
          }
          setError(true);
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form className={styles.form}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Field component={TextField} variant="standard" name="email" type="email" label="Email" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                variant="standard"
                type="password"
                label="Password"
                name="password"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={isSubmitting}
                type="submit"
                size="large"
                variant="outlined"
                color="primary"
                fullWidth
                endIcon={isSubmitting ? <CircularProgress size={16} /> : <ArrowRight />}
              >
                {isSubmitting ? 'Logging in' : 'Log in'}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="end">
                <Link className={styles.resetLink} style={{ color: theme.palette.primary.main }} to="/reset-password">
                  Forgot password?
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
