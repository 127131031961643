import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import styles from '../auth.module.scss';
import { useAuth } from '../../shared/hooks/useAuth';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmButton from '../../shared/components/buttons/ConfirmButton';
import CancelButton from '../../shared/components/buttons/CancelButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import useApi from '../../shared/hooks/useApi';

export default function TOSModal() {
  const [tosAccepted, selectTosAccepted] = useState(false);
  const { logout } = useAuth();

  const tooltipTitle = !tosAccepted ? 'Please accept TOS to proceed' : '';

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { acceptTos } = useApi();

  const { mutate, isLoading } = useMutation(() => acceptTos(), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['fetchMe']);
      enqueueSnackbar('TOS accepted', { variant: 'success' });
    },
    onError(e) {
      console.error(e);
      enqueueSnackbar('Failed to accept TOS', { variant: 'error' });
    },
  });

  const onAccept = () => {
    mutate();
  };

  const onClose = () => {
    logout();
  };

  return (
    <Dialog fullWidth maxWidth="md" open={true} onClose={onClose}>
      <DialogTitle style={{ marginTop: 15 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography>Prior to accessing the platform, please read and accept the TOS.</Typography>
          {onClose ? (
            <IconButton className={styles.tosModal__btnClose} onClick={onClose} size="large">
              <CloseIcon fontSize="large" />
            </IconButton>
          ) : null}
        </Box>
      </DialogTitle>
      <DialogContent className={styles.tosModal__content}>
        <h1>Regulamin korzystania z usługi debtSpot świadczonej przez Teonite Ventures Sp. z o.o.</h1>
        <p>
          Niniejszy regulamin określa zasady i warunki korzystania przez instytucje bankowe (dalej: Klienci) z usługi
          debtSpot świadczonej przez Teonite Ventures Sp. z o.o. (dalej: Operator).
        </p>

        <p>
          Operator umożliwia Klientom korzystanie z serwisu, funkcjonującego w ramach infrastruktury Operatora, na
          potrzeby przeprowadzenia przez Klientów testów funkcjonalnych w ramach pośrednictwa w sprzedaży tzw. portfeli
          NPL (dalej: Serwis).
        </p>

        <h2>Obowiązki Klientów</h2>
        <p>Klienci zobowiązani są w szczególności do:</p>
        <ul>
          <li>
            korzystania z Serwisu w sposób niezakłócający jego funkcjonowania oraz zgodny z przepisami obowiązującego
            prawa i niniejszym regulaminem;
          </li>
          <li>
            niedostarczania i niezamieszczania w Serwisie treści zabronionych przez przepisy obowiązującego prawa, a
            także danych wrażliwych, osobowych lub objętych tajemnicą bankową.
          </li>
        </ul>

        <h2>Odpowiedzialność Operatora</h2>
        <p>
          Operator nie ponosi odpowiedzialności z tytułu naruszenia praw osób trzecich oraz wyrządzenia szkody osobom
          trzecim na skutek oraz w związku z działaniami Klientów w ramach Serwisu, w szczególności nie ponosi
          odpowiedzialności za treści dostarczone lub zamieszczone w Serwisie przez Klientów, a także nie wstępuje w
          stosunek powierzenia w zakresie tych treści.
        </p>

        <h2>Zawarcie Umowy</h2>
        <p>
          Zawarcie umowy pomiędzy Operatorem a Klientem następuje w momencie rozpoczęcia korzystania z Serwisu przez
          Klienta, pod warunkiem zaakceptowania niniejszego regulaminu.
        </p>

        <h2>Minimalne Wymagania Techniczne</h2>
        <p>Minimalne wymagania techniczne systemu komputerowego, z którego Klient może korzystać z Serwisu to:</p>
        <ul>
          <li>dostęp do sieci Internet,</li>
          <li>przeglądarka Chrome, Internet Explorer lub inna w najnowszej dostępnej wersji.</li>
        </ul>

        <h2>Zablokowanie Dostępu</h2>
        <p>
          Operator zastrzega sobie prawo do zablokowania dostępu Klienta do Serwisu lub usunięcia z niego danych, w
          przypadku powzięcia uzasadnionej wątpliwości, że Klient korzysta z niego w sposób niezgodny z przepisami
          obowiązującego prawa lub postanowieniami niniejszego regulaminu. W przypadku potwierdzenia powyższej
          wątpliwości, Operator jest uprawniony do usunięcia konta Klienta w Serwisie.
        </p>

        <h2>Reklamacje</h2>
        <p>
          Wszelkie reklamacje związane ze świadczeniem usług przez Operatora oraz pytania dotyczące korzystania z
          Serwisu należy przesyłać na adres poczty elektronicznej{' '}
          <a href="mailto:office@teonite.com">office@teonite.com</a> z dopiskiem „Reklamacja”. Operator rozpatruje
          reklamację w ciągu 7 dni roboczych od dnia jej otrzymania oraz informuje niezwłocznie składającego reklamację,
          za pomocą poczty elektronicznej, o sposobie jej rozpatrzenia. W sytuacji gdy podane w reklamacji dane lub
          informacje wymagają uzupełnienia, Operator zwraca się, przed rozpatrzeniem reklamacji, do składającego
          reklamację o jej uzupełnienie. Czas udzielania dodatkowych wyjaśnień przez składającego reklamację przedłuża
          odpowiednio okres rozpatrywania reklamacji. Rozpatrzenie reklamacji przez Operatora jest ostateczne.
        </p>

        <h2>Rozwiązanie Umowy</h2>
        <p>
          Umowa pomiędzy Operatorem a Klientem zostaje rozwiązana w momencie usunięcia konta w Serwisie przez
          którąkolwiek ze Stron.
        </p>

        <h2>Spory</h2>
        <p>
          Wszelkie spory związane z usługami świadczonymi przez Operatora na podstawie niniejszego regulaminu będą
          rozstrzygane przez właściwy dla siedziby Operatora sąd powszechny w Polsce.
        </p>

        <p>
          W sprawach nieuregulowanych niniejszym regulaminem zastosowanie mają postanowienia Kodeksu cywilnego i
          odpowiednich przepisów prawa polskiego.
        </p>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'start', marginLeft: 15 }}>
        <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            data-testid="nda-accept"
            label="I accept the Terms Of Service"
            control={<Checkbox checked={tosAccepted} onChange={() => selectTosAccepted(!tosAccepted)} />}
          />
        </FormGroup>
      </DialogActions>
      <DialogActions style={{ justifyContent: 'space-between', marginBottom: 15, marginRight: 30, marginLeft: 15 }}>
        <Box>
          <Tooltip title={tooltipTitle} placement="top">
            <span style={{ marginRight: 30 }}>
              <ConfirmButton disabled={!tosAccepted} loading={isLoading} onClick={onAccept}>
                Accept
              </ConfirmButton>
            </span>
          </Tooltip>

          <CancelButton onClick={onClose} disabled={isLoading}>
            Decline
          </CancelButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
