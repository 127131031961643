import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import styles from '../Auth/auth.module.scss';
import ArrowRight from '../shared/components/icons/ArrowRight';
import CustomLink from '../shared/components/CustomLink';
import AuthLayout from '../Auth/AuthLayout';
import useApi from '../shared/hooks/useApi';

export default function BidConfirmation() {
  const [error, setError] = useState(false);
  const [isConfirmed, setConfirmed] = useState(false);

  const { bidId, token } = useParams<{ bidId: string; token: string }>();

  const api = useApi();

  return (
    <AuthLayout title="Confirm Bid" errorMsg={error ? 'Unable to confirm bid.' : ''}>
      {isConfirmed ? (
        <CustomLink label="Bid confirmed" linkText="Log in to your account" linkUrl="/login" />
      ) : (
        <Formik
          initialValues={{}}
          onSubmit={async (_, { setSubmitting }) => {
            setError(false);
            if (bidId && token) {
              try {
                await api.bid.confirm(bidId, token);
                setConfirmed(true);
              } catch (e) {
                setError(true);
              }
            }
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form className={styles.form}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography>Please click the button below to confirm your bid.</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    size="large"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    endIcon={!isSubmitting && <ArrowRight />}
                  >
                    {isSubmitting ? <CircularProgress /> : 'Confirm'}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </AuthLayout>
  );
}
