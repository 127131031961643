import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import {
  getStoredAccessToken,
  getStoredRefreshToken,
  removeStoredAccessToken,
  removeStoredRefreshToken,
  storeAccessToken,
  storeRefreshToken,
} from '../utils/authToken';

interface Auth {
  isLoggedIn: boolean;
  tempAccessToken?: string;
  ephemeralToken?: string;
  login: (data: LoginData) => void;
  logout: () => void;
  refreshAccessToken: () => void;
}

interface LoginData {
  email: string;
  password: string;
  refCode: string | null;
}

interface LoginResponse {
  access: string;
  refresh: string;
}

interface TokenRefreshResponse {
  access: string;
  refresh: string;
}

const defaultAuth = {
  isLoggedIn: false,
  login: () => null,
  loginCode: () => null,
  logout: () => null,
  refreshAccessToken: () => null,
};

const authContext = createContext<Auth>(defaultAuth);

export function AuthProvider({ children }: { children: ReactNode }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [isLoggedIn, setLoggedIn] = useState(Boolean(getStoredAccessToken()));

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const login = async (data: LoginData) => {
    const { access, refresh } = await axios.post('/api/auth/login/', data).then((res) => res.data as LoginResponse);
    storeAccessToken(access);
    storeRefreshToken(refresh);
    setLoggedIn(true);
    await queryClient.invalidateQueries(['fetchMe']);
  };

  const logout = () => {
    axios
      .post('/api/auth/logout/', {}, { headers: { Authorization: `JWT ${getStoredAccessToken()}` } })
      .then()
      .finally(() => {
        removeStoredAccessToken();
        removeStoredRefreshToken();
        setLoggedIn(false);
        queryClient.clear();
        window.location.replace('/login');
        enqueueSnackbar('Logged out', { variant: 'success' });
      });
  };

  const refreshAccessToken = async () => {
    const refreshToken = getStoredRefreshToken();
    const { access, refresh } = await axios
      .post('/api/auth/token/refresh/', { refresh: refreshToken })
      .then((res) => res.data as TokenRefreshResponse);
    storeAccessToken(access);
    storeRefreshToken(refresh);
  };

  return {
    isLoggedIn,
    login,
    logout,
    refreshAccessToken,
  };
}
