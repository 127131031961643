import React, { useMemo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from '../Auth/Login';
import PortfolioList from '../Portfolios/PortfolioList';
import Register from '../Auth/Register';
import UserProfilePage from '../UserProfile/UserProfilePage';
import ResetPassword from '../Auth/ResetPassword';
import ResetPasswordConfirm from '../Auth/ResetPasswordConfirm';
import PortfolioDetails from '../Portfolios/PortfolioDetails';
import BidConfirmationPage from '../BidConfirmationPage';
import OrderbookPage from '../OrderbookPage/OrderbookPage';
import CompanyKycPage from '../CompanyKycPage';
import CompanyUsersPage from '../CompanyUsersPage';
import { useAuth } from '../shared/hooks/useAuth';
import AutoLogout from './AutoLogout';
import styles from './app.module.scss';
import Index from './NavBar';
import PortfolioManagerPage from '../Admin/PortfolioManagerPage';
import PortfolioDetailsPage from '../Admin/PortfolioManagerPage/PortfolioDetailsPage';
import PortfolioSnapshotDetailsPage from '../Admin/PortfolioManagerPage/PortfolioSnapshotDetailsPage';
import SetPassword from '../Auth/SetPassword';
import LoadingBackdrop from '../shared/components/LoadingBackdrop';
import useUser from '../shared/hooks/useUser';
import CompanyKycAccept from '../CompanyKycPage/CompanyKycAccept';
import BuyerManagerPage from '../Admin/BuyerManagerPage';
import AdminUserManagerPage from '../Admin/AdminUserManagerPage';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MessageCenter from '../MessageCenterPage/MessageCenter';
import TOSModal from '../Auth/Register/TOSModal';

const loaderMessage = 'Loading, please wait.';

function RequireAuth({ children }: { children: JSX.Element }) {
  const { isLoggedIn } = useAuth();
  const { isUserFetched, acceptedTos } = useUser();
  const theme = useTheme();

  if (isLoggedIn && !isUserFetched) {
    return <LoadingBackdrop text={loaderMessage} open />;
  }

  if (isLoggedIn && !acceptedTos) {
    return <TOSModal />;
  }

  return isLoggedIn ? (
    <AutoLogout>
      <div className={styles.root}>
        <Index />
        <Box component="div" className={styles.body} sx={{ backgroundColor: theme.palette.backgroundColor.main }}>
          {children}
        </Box>
      </div>
    </AutoLogout>
  ) : (
    <Navigate to="/login" />
  );
}

function RequireCompanyUser({ children }: { children: JSX.Element }) {
  const { user, isUserFetched } = useUser();
  const hasCompany = useMemo(() => Boolean(user?.company), [user]);

  if (!isUserFetched) {
    return <LoadingBackdrop text={loaderMessage} open />;
  }

  return hasCompany ? <RequireAuth>{children}</RequireAuth> : <Navigate to="/user_profile" />;
}

function RequireAdmin({ children }: { children: JSX.Element }) {
  const { user, isUserFetched } = useUser();
  const isAdmin = useMemo(() => Boolean(user?.isAdmin), [user]);

  if (!isUserFetched) {
    return <LoadingBackdrop text={loaderMessage} open />;
  }

  return isAdmin ? <RequireAuth>{children}</RequireAuth> : <Navigate to="/" />;
}

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-password/confirm/:uid/:token" element={<ResetPasswordConfirm />} />
        <Route path="/set-password/:uid/:token" element={<SetPassword />} />
        <Route
          path="/marketplace"
          element={
            <RequireAuth>
              <PortfolioList />
            </RequireAuth>
          }
        />

        <Route
          path="/marketplace/portfolio/:id"
          element={
            <RequireAuth>
              <PortfolioDetails />
            </RequireAuth>
          }
        />

        <Route
          path="/user_profile"
          element={
            <RequireAuth>
              <UserProfilePage />
            </RequireAuth>
          }
        />

        <Route
          path="/company_kyc"
          element={
            <RequireAuth>
              <CompanyKycPage />
            </RequireAuth>
          }
        />

        <Route
          path="/company_kyc/:companyId"
          element={
            <RequireAuth>
              <CompanyKycAccept />
            </RequireAuth>
          }
        />

        <Route
          path="/company_users"
          element={
            <RequireCompanyUser>
              <CompanyUsersPage />
            </RequireCompanyUser>
          }
        />

        <Route
          path="/orderbook"
          element={
            <RequireAuth>
              <OrderbookPage />
            </RequireAuth>
          }
        />

        <Route
          path="/message_center"
          element={
            <RequireAuth>
              <MessageCenter />
            </RequireAuth>
          }
        />

        <Route path="/bid-confirm/:bidId/:token" element={<BidConfirmationPage />} />

        <Route
          path="/portfolio_manager"
          element={
            <RequireAdmin>
              <PortfolioManagerPage />
            </RequireAdmin>
          }
        />

        <Route
          path="/portfolio_manager/:id"
          element={
            <RequireAdmin>
              <PortfolioDetailsPage />
            </RequireAdmin>
          }
        />

        <Route
          path="/portfolio_manager/snapshots/:id"
          element={
            <RequireAdmin>
              <PortfolioSnapshotDetailsPage />
            </RequireAdmin>
          }
        />

        <Route
          path="/buyers"
          element={
            <RequireAdmin>
              <BuyerManagerPage />
            </RequireAdmin>
          }
        />

        <Route
          path="/users_admins"
          element={
            <RequireAdmin>
              <AdminUserManagerPage />
            </RequireAdmin>
          }
        />

        <Route path="*" element={<Navigate to="/marketplace" replace />} />
      </Routes>
    </BrowserRouter>
  );
}
